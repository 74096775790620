body {
    font-family: Arial, sans-serif;
}

.close-chat {
    padding: 5px;
    cursor: pointer;
}

.chat-container {
    position: relative;
    background-color: transparent;
}

.chatbot-icon {
    position: fixed;
    /* padding: 4px  3px  0px 3px; */
    border:3px solid white;
    bottom: 50px;
    right: 60px;
    border-radius: 60%;
    cursor: pointer;
    transition: transform 0.2s;
    background: transparent;
    /* Glow effect */
    box-shadow: 0 0 15px #069F8B;
    animation: glow 1.5s ease-in-out infinite, jump 1.5s ease-in-out infinite;
}

@keyframes glow {
    0%, 100% {
        box-shadow: 0 0 15px #069F8B;
    }
    50% {
        box-shadow: 0 0 25px #069F8B;
    }
}

@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}
@keyframes hel {
    0%, 100% {
        
        opacity: 0;
        visibility: hidden;
    }
    50% {
        
        opacity: 1;
        visibility: visible;
    }
}



.chatbot-icon img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 99;
}

#chat-window {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    right: 20px;
    width: 300px;
    height: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    transition: left 0.3s ease, right 0.3s ease;
    z-index: 1000;
}
@media screen and (max-width: 768px) {
    #chat-window {
top: 30%;
        width: 70%;
        height: 60vh;
        border-radius: 10px;
        box-shadow: none;
    }
    .chatbot-icon {
        position: fixed;
        border:3px solid white;
        bottom: 50px;
        right: 20px;
        border-radius: 60%;
        cursor: pointer;
        transition: transform 0.2s;
        background: transparent;
        box-shadow: 0 0 15px #069F8B;
        animation: glow 1.5s ease-in-out infinite, jump 1.5s ease-in-out infinite;
    }
    .need-help {
        position: fixed;
        bottom: 120px;
        right: 80px !important;
        border-radius: 5px;
        background-color: white;
        color: black;
        padding: 2px 10px;
        animation: glow 1.5s ease-in-out infinite, hel 8s steps(1, end)  infinite;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    }
    .need-help::after {
        content: "";
        position: absolute;
        bottom: -8px !important; 
        left: 91%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid white; /* Match bubble background color */
      }
}
header {
    background: linear-gradient(180deg, #023932 0%, #069F8B 100%);
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.head {
    margin-left: 10px;
    position: relative;
    /* bottom: 8px; */
    font-size: larger;
    font-weight: bold;
}

.chat {
    padding: 10px;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 100px; /* Added to prevent overlap with buttons */
}

.icon {
    color: white;
}

.message {
    display: flex;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    max-width: 70%;
    word-wrap: break-word;
}

.user {
    color: white;
    background: linear-gradient(180deg, #023932 0%, #069F8B 100%);
    align-self: flex-end; /* Align user messages to the right */
    margin-left: auto; /* Push user messages to the right */
}

.bot {
    background-color: #f0f0f0;
    align-self: flex-start; /* Align bot messages to the left */
    margin-right: auto; /* Push bot messages to the left */
}

.chat::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

.chat {
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.action-buttons {
    position: absolute;
    bottom: 10px; /* Position above footer */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px; /* Adjust padding as needed */
}

.action-buttons button {
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 8px 60px;
    margin: 5px 0; /* Adjust margin for spacing */
    font-size: 16px;
    font-weight: bold;
    color: #046842;
    background-color: rgb(243, 249, 249);
    border-top: none;
    border-left: none;
    border-bottom: 2px solid black; /* Added all borders */
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 1px 1px 3px black; /* Box shadow */
    transition: box-shadow 0.3s ease;
}
.action-buttons button:hover {
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 8px 60px;
    margin: 5px 0; /* Adjust margin for spacing */
    font-size: 16px;
    font-weight: bold;
    color: #046842;
    background-color: white;
    border-top: none;
    border-left: none;
    border-bottom: 2px solid black; /* Added all borders */
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 1px 1px 3px black; /* Box shadow */
    transition: box-shadow 0.3s ease;
}

.action-buttons button:hover {
    transform: scale(1.01);
}

.head-img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.triangle {
    position: absolute;
    width: 0;
    height: 0;
}

.user .triangle {
    right: 4px;
    top: 100%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #069F8B;
}

.bot .triangle {
    left: 4px;
    top: 100%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f0f0f0;
}
.need-help {
    position: fixed;
    bottom: 120px;
    right: 120px;
    border-radius: 5px;
    background-color: white;
    color: black;
    padding: 2px 10px;
    animation: glow 1.5s ease-in-out infinite, hel 8s steps(1, end)  infinite;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.need-help::after {
    content: "";
    position: absolute;
    bottom: -10px; 
    left: 91%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid white; /* Match bubble background color */
  }


footer {
    display: flex;
    align-items: center;
    padding: 10px;
}

footer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 5px;
}

footer .send {
    background: linear-gradient(180deg, #023932 0%, #069F8B 100%);
    color: white;
    border: none;
    font-size: 16px;
    padding: 10px 12px 5px 12px;
    border-radius: 5px;
    cursor: pointer;
}

.send:hover{
   background:linear-gradient(180deg, #046a5c 0%, #11c8b0 100%) ;
}