/* @font-face {
  font-family: "Customfont";
  src: url("../public/fonts/recoleta-regulardemo.otf");
} */

* {
  box-sizing: border-box;
  margin: 0px;
  font-family: "Crimson Text", serif;
  /* font-family: "Customfont"; */
  padding: 0px;
}

.logo {
  width: 200px;
}

.video-contain {
  width: 800px;
  height: 800px;
  margin-top: 90px;
}
.section {
  /* background-color: #adddd6; */
  /* background-color: #3b546e; */
  background-image: url("../public/assets/bg-1.jpeg");
  /* background-image: url("../public/assets/bg-3.jpeg"); */
  filter: blur(3000%);
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-1 {
  background-color: #faf7f2;
  width: 100%;
}
.txt-2 {
  color: #455d58;
  text-align: center;
}

.txt-3 {
  color: #455d58;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  filter: opacity(0.7);
  background-color: #000;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  height: 0;
  transition: 0.5s ease;
}

.community-card:hover .overlay {
  height: 70px;
  font-weight: 600;
  opacity: 1;
  border: none;
}

.community-card {
  width: 250px;
  margin: 10px;
  height: 300px;
}

.ourpartner-img {
  width: 500px;
  height: 300px;
  border-radius: 10px;
}

.txt-h-v {
  color: black;
}

.img-proper {
  width: 100%;
  height: 250px;
}
.txt-5 {
  color: #000 !important;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
}
.txt-6 {
  font-size: 17px;
  font-weight: 500;
  color: #455d58;
  text-decoration: none;
}

.bg {
  background-image: url("../public/assets/Group\ 1699.jpg");
  width: 100%;
  height: 100vh;
  /* position: fixed; */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Keeps the background image fixed while scrolling */
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-card .ant-card-cover * > {
  border-radius: 8px 8px 8px 10px !important;
}
.community-img {
  width: 100%;
  height: 300px;
  border-radius: 8px 8px 8px 9px !important;
}

.text {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.navbar-contain {
  width: 100%;
  padding: 3px;
  color: #fff;
  /* background-image: url("../public/assets/bg-3.jpeg"); */
  /* background-color: #adddd6; */
  /* background-image: url("../public/assets/bg-1.jpeg"); */
  background-color: transparent;
  /* background-color: #000; */
  width: 100%;
  position: fixed;
  z-index: 100;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color 0.3s ease-in-out;
}

.scrolled-navbar {
  background-color: #000;
  opacity: 0.8; /* Change to desired color */
  background-image: none;
}

.video-background {
  /* position: relative; */
  height: 100vh;
  width: 100%;
  overflow: hidden;
  animation: slideIn 3s forwards;
}

/* Slide-in from left animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.crs {
  cursor: pointer;
}

.btn-anis {
  /* background-color: #455d58 !important; */
  background-color: black !important;
  color: #ffd169 !important;
  border: none !important;
  padding: 5px 30px;
  margin-left: 10px;
  transition: all 2s ease;
}

.btn-anis:hover {
  background-color: #f9f7f6 !important;
  color: #455d58 !important;
}

.video {
  position: relative;
}

.search-container {
  display: flex;
  width: 100%;
  align-items: center; /* Light border color */
  border-radius: 4px; /* Rounded corners */
  padding: 15px; /* Adjust padding as needed */
  /* background-color: #fff; Background color */
}
.select {
  width: 500px;
  color: white !important;
  background-color: transparent !important;
}

.select::placeholder {
  color: white !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  border: 1px solid white !important;
  color: black !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus-within {
  border: 1px solid white !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: transparent !important;
}

.search-container .ant-select {
  flex: 1;
}

.search-container .ant-btn {
  margin-left: 8px;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: white !important;
}

/* .btn {
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
} */

/* .btn-primary{
  background-color: white !important;
  color:gray !important;
  border:1px solid lightgray !important;
} */

:where(.css-dev-only-do-not-override-qnu6hi).ant-card-bordered {
  border: none !important;
}

.develop-card {
  width: 350px;
  margin: 10px;
  background-color: black;
}

.develop-img {
  width: 100%;
  height: auto;
}

:where(.css-dev-only-do-not-override-qnu6hi)[class^="ant-select"]
  [class^="ant-select"],
:where(.css-dev-only-do-not-override-qnu6hi)[class*=" ant-select"]
  [class^="ant-select"],
:where(.css-dev-only-do-not-override-qnu6hi)[class^="ant-select"]
  [class*=" ant-select"],
:where(.css-dev-only-do-not-override-qnu6hi)[class*=" ant-select"]
  [class*=" ant-select"] {
  color: black !important;
}

.develop-img:hover {
  filter: brightness(50%);
}

.txt-1 {
  color: white;
  text-align: center;
}

.partner {
  background-color: white;
  padding: 5px 20px;
  border-radius: 10px;
  width: 100%;
}

.product-image-1 {
  width: 250px;
  height: 200px;
}

.product-div {
  transition: transform 0.5s ease;
}

.greater {
  background-color: black;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.btn-1 {
  border: none;
  font-weight: 600;
  font-size: 20px;
  background-color: transparent;
  color: white;
}

.img-row {
  background-image: url("https://cloud.famproperties.com/fam/blog/857-105725.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  border-radius: 10px;
}

.left-side {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  border: 2px solid red;
  background-color: #008cba !important;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
}

.img-row:hover .left-side {
  width: 100%;
}

.txt-4 {
  color: white;
  font-weight: 600;
  margin-bottom: 0px;
}

.img-row-1 {
  background-image: url("https://cloud.famproperties.com/fam/blog/857-105725.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.btn-2 {
  background-color: rgb(62, 61, 61);
  color: #fff;
  border: none;
  padding: 5px 5px;
  border-radius: 5px;
}

.btn-6 {
  background-color: rgb(62, 61, 61);
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-3 {
  background-color: white;
  color: black;
  font-weight: 700;
  border: none;
  padding: 5px 5px;
  border-radius: 5px;
}
.btn-5 {
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: 600;
  width: 150px;
  border: none;
}

.icon {
  background-color: black;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
}

.color-txt {
  color: white !important;
}

.banner-6 {
  width: 100%;
  display: flex !important;
  justify-content: end !important;
}

.animation-2 {
  width: 100% !important;
}

.reviews {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-fluid-1-ani {
  width: 100% !important;
}

.btn-4 {
  background-color: #000 !important;
  opacity: 0.8;
  border: 1px solid black !important;
  padding: 5px 20px !important;
  color: #ffd169 !important;
  font-size: 20px;
  transition: all 2s ease;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.btn-4:hover {
  background-color: white;
  color: #455d58;
}

.animation-2-combine {
  position: relative !important;
}

.animation-2 {
  width: 560px !important;
  position: relative !important;
}

.banner-6-combine {
  flex-direction: column !important;
}

.banner-6 {
  width: 100%;
  display: flex !important;
  justify-content: end !important;
}

.img-fluid-1-ani {
  width: 100% !important;
}

.contentStyle {
  background-color: white;
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%; /* Adjust as needed */
  height: 300px; /* Adjust as needed */
}

.carousel-inner {
  display: flex;
  width: 100%;
  height: 300px;
  transition: transform 0.5s ease;
}

.carousel-item {
  width: 33.33%; /* Adjust based on the number of visible items */
}

.carousel-image {
  width: 100%;
  height: auto;
  display: block;
}

.nav-btn {
  position: absolute;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.help-card {
  width: 350px;
  margin: 10px;
  display: flex;
  /* background-color: #faf7f2; */
  background-color: #f9f7f6 !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-image {
  width: 400px;
  height: 300px;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.btn-ani {
  background-color: #455d58;
  color: #faf7f2;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 600;
  border: 2px solid #455d58 !important;
  border: none;
  transition: all 2s ease;
}

.btn-ani:hover {
  background-color: white;
  border: 2px solid #455d58;
  color: #455d58;
}

.btn-ani-1 {
  background-color: #000 !important;
  color: #ffd169 !important;
  padding: 7px 20px !important;
  font-size: 18px !important;
  font-weight: 600 !important ;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 2s ease;
}
.btn-ani-1:hover {
  background-color: #faf7f2 !important;
  color: #455d58 !important;
}

a {
  text-decoration: none;
}

.section-3 {
  padding-top: 150px;
}

.txt-4 {
  color: #faf7f2;
}

.size {
  font-size: 12px;
}

.ourpartner-card {
  width: 160px;
  padding: 10px;
  transition: all 2s ease;
  height: 100px;
}

.switch-defalut {
  width: 120px;
  color: white;
  background-color: white;
  border-radius: 7px;
}

.switch-defalut::placeholder {
  color: white !important;
}

.sort-txt {
  color: white;
  font-size: 20px;
}

.our-img-part {
  width: 100%;
}

.overlay-img-container {
  position: relative;
  width: 450px;
}

.overlay-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 0;
  border-radius: 10px;
  height: 100%;
  transition: 0.5s ease;
}

.img-2 {
  width: 450px;
  height: 300px;
}

.txt-img {
  width: 450px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-img-container:hover .overlay-img {
  width: 100%;
  border: 10px solid #455d58;
}

.ourpartner-card:hover {
  transform: scale(1.3);
  border: 5px solid #455d58 !important;
}

.icon-off {
  color: #ffd169;
  cursor: pointer;
}

.gaps {
  padding-top: 100px;
}

.custom-div {
  display: flex;
}

.less-div,
.greater-div {
  background-color: #000;
  opacity: 0.7;
  color: #ffd169 !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.flSzyA .slick-slide:not(.slick-center) img {
  border: none !important;
}
.lessthan,
.greaterthan {
  color: #455d58;
  padding: 2px;
  /* border:2px solid red; */
  background-color: transparent !important;
  border: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: end;
  font-weight: 600;
  font-size: 20px;
}

.d-icons {
  font-size: 24px;
  font-weight: 800 !important;
  color: #ffd169 !important;
  padding-top: 10px;
}

.txt-7 {
  color: #ffd169;
  font-size: 35px;
}

.txt-8 {
  font-size: 20px;
  /* color: #fff; */
  color: #ffd169;
}

.icon-offs {
  font-size: 20px;
  color: #faf7f2;
}

.gaps-img {
  gap: 40px;
  width: 550px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-all {
  padding-top: 80px;
}

.img-div-develop {
  width: 500px;
}
.img-div-develop > img {
  border-radius: 8px;
}

.img-div-helps {
  width: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 300px;
  background-color: #fff;
}

.img-div-ad {
  width: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 300px;
  background-color: transparent;
}

.gaps-3 {
  padding-top: 140px;
}

.help-img {
  width: 300px;
  height: 250px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

.home-hero[data-anim="true"] .home-hero__imagery-inner iframe {
  transform: translateX(-50%) scale(1.1);
}
.home-hero__imagery-inner iframe {
  left: 50%;
  transform: translateX(-50%) scale(1.2);
  width: 300%;
  z-index: 1;
}

.img-awards {
  width: 100%;
  height: 300px;
}

.awards-div {
  width: 500px;
  height: 300px;
}

.side-slide {
  width: 500px;
}

.off-plan-img > img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.bg-2 {
  background-color: white !important;
  padding: 20px;
  border-radius: 10px;
}
.off-txt-1 {
  margin-bottom: 0px !important;
  font-size: 24px;
  font-weight: 600;
}

.off-txt-2 {
  margin-bottom: 10px !important;
  font-size: 16px;
  font-weight: 400;
}

.off-icon {
  font-size: 25px;
  font-weight: 700;
}

.off-txt-3 {
  font-weight: 600;
  font-size: 16px;
}

.fix-contact {
  width: 50%;
}

.fix-contact-1 {
  width: 100%;
  position: sticky;
  top: 105px;
}

.inputs {
  padding: 15px;
  margin-bottom: 30px;
}

.inputs-text {
  width: 100%;
  padding: 10px 20px 70px 10px;
  background-color: white !important;
  color: black !important;
}

.btn-tour {
  background-color: #000 !important;
  color: #ffd169 !important;
  padding: 25px 30px;
  font-size: 18px;
  font-weight: 600;
}

.btn-tour:hover {
  background-color: #000 !important;
  color: #ffd169 !important;
  border: 1px solid #000 !important;
}

.txt-9 {
  /* color: #c4c4c4; */
  color: #ffd169;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.txt-9-1 {
  color: #ffd169;
  /* color: #c4c4c4; */
  font-size: 16px;
  font-weight: 800 !important;
  cursor: pointer;
}

.bg-3 {
  background-image: url("../public/assets/Group\ 1702.jpg");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center; /* Centers content vertically */
  justify-content: center;
}

.bg-4 {
  background-color: white;
  width: 50%;
  height: 200px;
  padding: 40px;
  border-radius: 10px;
  /* margin-top: 20px; */
}

.contact-form {
  background-color: transparent;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.css-b8e7cm {
  color: white !important;
}

:where(.css-qnu6hi).ant-dropdown .ant-dropdown-menu,
:where(.css-qnu6hi).ant-dropdown-menu-submenu .ant-dropdown-menu {
  background-color: #000 !important;
  color: #ffd169 !important;
}
:where(.css-qnu6hi).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content
  > a,
:where(.css-qnu6hi).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content
  > a {
  color: #ffd169 !important;
}
.form {
  width: 80%;
  margin-bottom: 10px;
  color: white !important;
  cursor: pointer;
  background-color: transparent !important;
  border: 2px solid white !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined {
  background-color: transparent !important;
}

.form::placeholder {
  color: white;
}

.txt-ctn {
  padding-left: 50px;
  text-align: center;
  /* color:white; */
}
.txt-form {
  color: white;
  font-size: 35px;
  font-weight: 400;
}
.cnt-div {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-off-1 {
  /* color: white; */
  cursor: pointer;
  color: black !important;
  font-size: 25px;
}

.ctn-txt {
  font-size: 20px;
  font-weight: 500;
  /* color: white; */
  text-align: center;
}
.ctn-txt > p {
  /* color: white; */
}

.form-btn {
  background-color: #000 !important;
  color: #ffd169 !important;
  margin-top: 20px;
  border: none !important;
}
.form-btn:hover {
  background-color: #000 !important;
  color: #ffd169 !important;
}

.css-1228x3t {
  color: #000 !important;
}
.sections {
  width: 100%;
  position: relative;
  background-image: url("../public/assets/bg-1.jpeg"); /* Replace with your actual image */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 110vh;
}

.section-r {
  width: 100%;
  position: relative;
  background-image: url("../public/assets/section-1.jpeg"); /* Replace with your actual image */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 110vh;
}

.buy {
  padding-left: 55px;
}

.footer {
  background-color: #000;
  opacity: 1;
  width: 100%;
  height: 350px;
}

/* Background Image Styling */
.background-image {
  background-image: url("../public/assets/bg-1.jpeg"); /* Replace with your actual image */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Place the image on top initially */
  opacity: 1;
  transition: opacity 1s ease-in-out; /* Smooth fade-out transition */
}

.background-image.fade-out {
  opacity: 0; /* Fades out after 2 seconds */
  z-index: -1; /* Send the image behind the video when it fades */
}

/* Video Container */
.video-container {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden; /* Initially hidden */
}

.content {
  position: absolute;
  z-index: 1;
  text-align: center;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-1 {
  position: absolute;
  z-index: 1;
  width: 30%;
  top: 55%;
  color: white;
  left: 22%;
  transform: translate(-50%, -50%);
}

.v-txt {
  font-size: 60px;
  font-family: "Rowdies", sans-serif;
}

.content-combine {
  width: 100%;
  /* background-color: rgb(58, 55, 55); */
  color: white;
  /* height: 100px; */
  position: relative;
  border-radius: 10px;
}

/* Video Element */
.video-content {
  width: 80%;
  object-fit: contain;
  margin-top: 100px;
  height: 500px;
}

.video-container.slide-in {
  visibility: visible;
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  to {
    left: 0;
  }
}

.row-offer {
  display: flex;
  flex-direction: column;
  gap: 140px;
}

.off-btn {
  background-color: #000;
  opacity: 0.7;
  color: white;
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
}

.off-btn-1 {
  background-color: white;
  color: #000;
  border-radius: 5px;
  border: none;
  padding: 2px;
  font-size: 17px;
  font-weight: 600;
  margin-top: 2px;
}
.flash {
  color: #ffd169;
  margin-top: 5px;
}

.flash-div {
  background-color: #000;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 50%;
}

.off-txt {
  color: #d9d9d9;
  font-size: 17px;
  font-weight: 600;
  background-color: #000;
  opacity: 0.9;
  padding: 5px 1px;
  border-radius: 5px;
}

.btn-5-c {
  background-color: #455d58;
  color: #f9f7f6;
  padding: 10px 20px;
  border: 2px solid #455d58 !important;
  font-size: 16px;
  transition: all 2s ease;
}

.btn-5-c:hover {
  background-color: #f9f7f6;
  color: #455d58;
}

.txt-c {
  color: #455d58;
}

.txt-c-1 {
  padding-top: 30px;
  color: #000;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-qnu6hi).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  background-color: #000 !important;
  color: white !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content
  > a,
:where(.css-dev-only-do-not-override-qnu6hi).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content
  > a {
  /* color:white !important; */
  color: #ffd169 !important;
}

.section-bg {
  background-image: url("../public/assets/achive.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  background-attachment: fixed;
}

.achive-bg {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.a-txt {
  font-size: 60px;
}

.a-txt-1 {
  font-size: 30px;
}

.down {
  font-size: 24px !important;
}
.ms {
  margin-top: 10px;
}

.bg-our {
  /* background-image: url("../public/assets/service.png"); */
  background-image: url("../public/assets/ourbg.jpeg");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .navbar-section {
  width: 100%;
  height: 104px;
} */

.img-hand {
  width: 40%;
  display: flex;
  justify-content: center;
  height: 415px;
  align-items: end;
}

.s-content-txt {
  width: 60%;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.img-hand > img {
  width: 100%;
  height: 370px;
}

.s-txt {
  color: white;
  font-size: 20px;
}

.css-ezppyz-MuiButtonBase-root-MuiRadio-root {
  color: white !important;
}
.s-content {
  width: 100%;
  height: 585px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.radios {
  color: white !important;
}

.radios-h {
  color: #ffd169 !important;
}

.filter-card {
  width: 350px;
  height: 370px;
}

.css-188mx6n-MuiSlider-root {
  color: black !important;
  padding: 10px !important;
}

.css-7o8aqz-MuiSlider-rail {
  width: 90% !important;
}

.css-cp2j25-MuiSlider-thumb::before {
  width: 80% !important;
  height: 80% !important;
}

.css-hzp7sc-MuiSlider-mark {
  display: none !important;
}

.txt-f {
  font-size: 16px;
  font-weight: 600;
}
.txt-f-1 {
  font-size: 14px;
  font-weight: 400;
  color: grey;
  margin-bottom: 5px;
}
.f-icon {
  font-size: 17px;
  color: #000 !important;
  cursor: pointer;
}

.our-div {
  width: 530px;
  height: 250px;
  border-radius: 20px;
}

.txt-h {
  font-size: 36px;
  color: #ffd169;
}

.txt-h-1 {
  font-size: 16px;
  color: white;
}
.txt-area {
  width: 80%;
  padding-left: 10px;
  margin-top: 10px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  cursor: pointer;
}

.txt-area::placeholder {
  color: white !important;
}

.txt-8-1 {
  color: white;
}

/* Apply hover effect to all links */
.txt-6 {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s, text-decoration 0.3s;
}

/* Hover effect */
.txt-6:hover {
  text-decoration: underline;
}

.home-bg {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.find-h {
  font-size: 22px;
  font-weight: 600;
}

.find-a {
  font-size: 20px;
  font-weight: 700;
}
.switch-h {
  width: 100%;
  border: 1px solid black !important;
  border-radius: 6px;
}
.s-txt-s {
  color: white;
  font-size: 27px;
}

.btn-s {
  background-color: #ffd169 !important;
  color: black !important;
  border: 1px solid #ffd169 !important;
  padding: 8px 125px !important;
  font-size: 16px;
  font-weight: 500;
}

.s-txt {
  color: #000;
  gap: 10px;
  font-size: 16px !important;
}

.s-txt-d {
  text-decoration: 1px underline black;
}
.rate {
  font-size: 14px !important;
}

.review-txt {
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid gray !important;
  background-color: white !important;
  border-radius: 5px;
}

.title {
  font-weight: 700;
}

.map-container {
  position: relative;
  width: 100%;
  height: 450px;
  background-image: url("https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2748.7067658221395!2d55.334499!3d25.251787!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1726558826827!5m2!1sen!2sin"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
}

.content-ad {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.content-ad > h3 {
  color: white;
  font-size: 40px;
  font-weight: 700;
  padding-left: 80px;
}

.ad-txt {
  text-align: center;
  color: black;
  font-size: 40px;
  font-weight: 600;
}

.bg-ad {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-img {
  width: 150px;
}

.txt-8-2 {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.txt-8-3 {
  font-size: 20px;
  font-weight: 600;
  color: black;
  /* text-decoration: 1px underline #ffd169; */
}

.txt-7-1 {
  color: black;
  font-size: 30px;
  font-weight: 600;
}

.btn-ad {
  background-color: #ffd169 !important;
  color: #000 !important;
  border: 1px solid #ffd169 !important;
  padding: 20px 60px;
  font-size: 18px;
  font-weight: 600;
}
.btn-ad:hover {
  background-color: #ffd169 !important;
  color: #000 !important;
}

.txt-ad-h {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.txt-ad-p {
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.less-icon,
.greater-icon {
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  border-radius: 50%;
  background-color: #000;
  color: #ffd169;
}
:where(.css-dev-only-do-not-override-qnu6hi).ant-space-gap-col-small {
  column-gap: 0px;
}
.combine-navs {
  display: flex;
  gap: 20px;
  width: 400px;
  justify-content: space-evenly;
}
.navbar-whole {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  display: flex;
  justify-content: start !important;
}
.btn-div-m {
  margin-top: 20px;
}

.off-plan-img {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.off-plan-img img {
  width: 100%;
  height: 300px !important;
}

.section-3 .row {
  display: flex;
  overflow: hidden;
  position: relative;
}

.section-3 .row {
  display: flex;
  overflow: hidden;
  position: relative;
}
.carrers-container {
  padding: 130px 20px 20px 0px;
}
.txt-career-p {
  font-size: 16px;
  /* text-align: center; */
}

.txt-career-h {
  font-size: 30px;
  text-align: center;
}
.careers {
  margin-bottom: 10px;
  width: 100%;
}

.careers::placeholder {
  color: grey !important;
}
.label-carrers {
  font-size: 18px;
  font-weight: 600;
}
.careers-btn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.c-box-1 {
  width: 50%;
}
.c-box-2 {
  width: 50%;
}
.c-box-combine {
  display: flex;
  width: 70%;
  background-color: white;
  box-shadow: 3px 3px 5px lightgray;
  padding: 20px;
  border-radius: 10px;
  gap: 30px;
}
.c-box-c {
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.btn-carrer {
  padding: 5px 70px;
  background-color: #000 !important;
  color: #ffd169 !important;
  font-size: 17px !important;
  font-weight: 600;
  border: 1px solid black !important;
  opacity: 0.9;
}

.private-txt-h {
  font-size: 20px;
  font-weight: 700;
}

.private-txt-p {
  font-size: 18px;
  font-weight: 500;
}
.footer-f {
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-footer {
  color: #ffd169;
  font-size: 16px;
  margin-bottom: 0px;
}

.txt-9:hover {
  color: white;
}

.footer-icon {
  font-size: 13px;
  color: #ffd169;
}

.flag {
  width: 18px;
  margin-bottom: 0px;
}
.flag-div {
  display: flex;
  gap: 10px;
  height: 25px;
}
.flag > img {
  width: 100%;
}
@media screen and (min-width: 380px) and (max-width: 578px) {
  .logo {
    width: 150px;
  }
  .careers {
    margin-bottom: 10px;
    width: 100%;
  }
  .c-box-1 {
    width: 100%;
  }
  .c-box-2 {
    width: 100%;
  }
  .c-box-combine {
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carrers-container {
    padding: 130px 20px 20px 20px;
  }
  /* For default toggle icon color */
  .navbar-toggler-icon {
    background-color: #ffd169;
    /* Default color for the icon */
  }

  .fix-contact {
    width: 100%;
  }
  .img-div-ad,
  .help-img {
    width: 100%;
  }
  .product-div-2 {
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  .txt-7-1 {
    text-align: center;
  }
  .txt-8-2 {
    text-align: center;
    font-size: 16px;
  }
  .txt-8-3 {
    text-align: center;
    font-size: 16px;
  }

  .navbar-contain {
    padding: 1rem;
  }
  .dropdown-menu-mobile {
    margin-left: 30px;
  }

  .head-nav:hover {
    color: white;
  }
  .navbar-whole {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }

  .navbar-brand img {
    max-width: 150px;
  }

  .navbar-collapse {
    flex-direction: column;
  }

  .navbar-collapse .nav-link {
    padding: 0.5rem 1rem;
    text-align: center;
  }
  .navbar-contain {
    display: none;
  }
  .bg-nav {
    background-image: url("../public/assets/bg-1.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: fixed;
    z-index: 80;
  }
  .servicebd-color {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .head-nav {
    font-size: 18px;
    color: #ffd169;
  }
  .video-container {
    width: 100%;
    align-items: center;
    margin-top: 50px;
  }

  .video-content {
    margin-top: 0px;
    object-fit: fill;
    width: 100%;
    height: 400px !important;
  }
  .v-txt {
    font-size: 25px;
    color: #fff;
  }
  .content-1 {
    top: 53%;
    width: 85%;
    left: 53%;
  }

  .footer {
    height: auto !important;
  }
  .sections {
    height: 500px !important;
    background-image: url("../public/assets/bg-m.jpg");
    width: 100%;
  }
  .section-r {
    background-image: url("../public/assets/bg-m-1.jpeg");
    width: 100%;
    height: 500px;
  }
  .m-gaps {
    padding-top: 150px;
  }
  .search-container {
    width: 100%;
    padding: 0px !important;
  }
  .select {
    width: 100%;
    padding: 2px !important;
  }
  :where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:hover {
    border: 1px solid white !important;
  }
  .content-combine {
    width: 100%;
  }
  .text-row {
    padding-left: 30px;
  }

  .page {
    display: flex;
    justify-content: center !important;
  }
  .btn-anis {
    padding: 2px 20px !important;
  }
  .content {
    bottom: 19%;
  }
  .img-div-develop {
    width: 100% !important;
  }
  .img-div-develop > img {
    width: 100% !important;
    border-radius: 8px;
  }
  .gaps-img {
    width: 100% !important;
    padding-top: 0px !important;
    display: flex;
    gap: 5px !important;
    justify-content: center;
    height: 100px !important;
  }
  .row-develops {
    margin-top: 30px;
  }
  .gaps {
    padding-top: 0px !important;
  }
  .v-img {
    width: 25px;
  }
  .v-combine-image {
    justify-content: center !important;
    gap: 0px;
    padding: 0px !important;
  }
  .v-img-txt {
    font-size: 12px !important;
  }
  .m-txt {
    text-align: center;
  }
  .content-div {
    width: 100% !important;
    padding: 20px;
  }
  .txt-7 {
    font-size: 20px !important;
    /* text-align: center !important; */
  }
  .product-div-1 {
    width: 100% !important;
    padding: 10px !important;
    margin-top: 35px;
    justify-content: center !important;
  }
  .txt-8 {
    text-align: center !important;
    padding-bottom: 0px !important;
  }
  .icons-m {
    justify-content: center !important;
  }
  .btn-div-m {
    justify-content: center !important;
    margin-top: 5px !important;
  }
  .equal-height-row {
    gap: 30px;
  }
  .bg {
    display: none;
  }
  .location {
    flex-direction: column !important;
    gap: 0px !important;
  }
  .product-div-3 {
    margin-top: 50px;
  }
  .img-div-helps {
    width: 100% !important;
    height: 200px !important;
  }
  .help-img {
    width: 100% !important;
    height: 200px !important;
  }
  .community-m {
    padding-top: 20px;
  }
  .side-slide {
    width: 100% !important;
  }
  .rows {
    padding: 30px;
  }
  .img-awards,
  .awards-div {
    width: 100% !important;
    border-radius: 5px;
  }
  .section-bg {
    height: auto !important;
  }
  .img-achive {
    width: 30px;
  }
  .fix-contact-1 {
    margin-top: 20px;
  }
  .off {
    padding-left: 35px !important;
  }
  .row-achive {
    flex-direction: row !important;
  }
  .a-txt {
    font-size: 20px !important;
  }
  .a-txt-1 {
    font-size: 18px !important;
  }
  .animation-2-combine {
    width: 100% !important;
  }
  .reviews {
    width: 100% !important;
    padding: 20px 30px 20px 20px !important;
  }
  .footer {
    height: auto !important;
    background-color: #000 !important;
  }

  .review-div {
    width: 100%;
    margin-left: 5px;
  }
  .gaps-3 {
    padding-top: 120px !important;
  }
  .on-plan-row {
    gap: 20px !important;
  }
  .our-div {
    width: 100% !important;
    height: 200px !important;
  }
  .row-our {
    margin-top: 40px;
    gap: 30px;
  }
  .s-content {
    width: 100%;
    height: 400px !important;
  }
  .s-content-txt {
    width: 60% !important;
    height: 300px;
    justify-content: center;
  }
  .img-hand {
    width: 50% !important;
  }
  .gaps-row {
    gap: 30px !important;
  }
  .img-hand > img {
    height: 300px !important;
    width: 100% !important;
  }
  .section-3 {
    padding-bottom: 40px;
  }
  .file {
    width: 295px !important;
  }

  .txt-ctn {
    padding-left: 20px !important;
  }
  .s-txt-s {
    font-size: 14px !important;
  }
  .bg-our {
    height: 400px !important;
  }
  .nav-icon {
    border: 1px solid #ffd169 !important;
    color: #ffd169 !important;
  }
  .our-row-h {
    width: 100%;
    margin-left: 0px;
    height: 200px;
  }
}

.our-row-h {
  width: 100%;
  height: auto;
}

.file {
  background-color: white !important;
  width: 360px;
  color: grey !important;
  border: 1.5px dotted grey !important;
}

.on-plan-row {
  row-gap: 20px;
}

@media screen and (min-width: 579px) and (max-width: 950px) {
  .logo {
    width: 250px !important;
  }
  .video-content {
    margin-top: 0px;
    width: 100%;
    height: 450px !important;
    object-fit: fill;
  }

  .product-div-3 {
    margin-top: 170px;
  }
  .file {
    width: 630px !important;
  }
  .v-txt {
    font-size: 40px;
  }
  .navbar-contain {
    padding: 1rem;
  }
  .dropdown-menu-mobile {
    margin-left: 30px;
  }
  .fix-contact {
    width: 100%;
  }
  .btn-s {
    padding: 8px 90px !important;
  }
  .img-div-ad,
  .help-img {
    width: 100%;
  }

  .careers {
    margin-bottom: 10px;
    width: 100%;
  }
  .c-box-1 {
    width: 100%;
  }
  .txt-8-1 {
    font-size: 24px;
    text-align: center;
  }
  .help-btn-div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .c-box-2 {
    width: 100%;
  }
  .c-box-combine {
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .txt-9-1 {
    font-size: 25px;
  }

  .carrers-container {
    padding: 130px 20px 20px 20px;
  }
  .navbar-toggler-icon {
    background-color: #ffd169;
    /* Default color for the icon */
  }
  .product-div-2 {
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  .txt-7-1 {
    text-align: center;
  }
  .txt-8-2 {
    text-align: center;
    font-size: 16px;
  }
  .txt-8-3 {
    text-align: center;
    font-size: 16px;
  }
  .txt-9 {
    font-size: 18px;
  }
  .head-nav:hover {
    color: white;
  }
  .navbar-whole {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }

  .navbar-brand img {
    max-width: 150px;
  }

  .navbar-collapse {
    flex-direction: column;
  }

  .navbar-collapse .nav-link {
    padding: 0.5rem 1rem;
    text-align: center;
  }
  .navbar-contain {
    display: none;
  }
  .bg-nav {
    background-image: url("../public/assets/bg-1.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: fixed;
    z-index: 80;
  }
  .pad {
    padding-top: 120px;
  }
  .servicebd-color {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .head-nav {
    font-size: 25px;
    color: #ffd169;
  }
  .video-container {
    width: 100%;
    align-items: start;
    margin-top: 150px;
  }
  .sections {
    height: 650px !important;
    background-image: url("../public/assets/bg-m.jpg");
    width: 100%;
  }
  .section-r {
    background-image: url("../public/assets/bg-m-1.jpeg");
    width: 100%;
    height: 500px;
  }
  .search-container {
    width: 100%;
    padding: 0px !important;
  }
  .select {
    width: 100%;
    padding: 2px !important;
  }
  .content-1 {
    top: 45%;
    width: 70%;
    left: 53%;
  }
  :where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:hover {
    border: 1px solid white !important;
  }
  .content-combine {
    width: 100%;
  }
  .btn-anis {
    padding: 2px 20px !important;
  }
  .content {
    bottom: 20%;
  }
  .img-div-develop {
    width: 100% !important;
  }
  .gaps-img {
    width: 100% !important;
    padding-top: 0px !important;
    height: 100px !important;
  }
  .gaps {
    padding-top: 50px !important;
  }
  .v-img {
    width: 60px;
  }
  .v-combine-image {
    justify-content: space-between !important;
    gap: 0px;
    padding: 0px !important;
  }
  .v-img-txt {
    font-size: 18px !important;
  }
  .m-txt {
    /* text-align: center; */
    font-size: 40px;
  }
  .txt-7 {
    font-size: 40px !important;
    text-align: center;
  }
  .product-div-1 {
    width: 100% !important;
    padding: 30px !important;
    margin-top: 140px;
    justify-content: center !important;
  }
  .section-r {
    height: auto;
  }

  .row-develops {
    margin-top: 130px;
  }
  .txt-8 {
    text-align: center !important;
  }
  .icons-m {
    justify-content: center !important;
  }
  .btn-div-m {
    justify-content: center !important;
  }
  .equal-height-row {
    gap: 30px;
  }
  .bg {
    display: none;
  }
  .img-div-helps {
    width: 100% !important;
    height: 350px !important;
    margin-top: 50px;
  }
  .help-img {
    width: 100% !important;
    height: 380px !important;
  }
  .community-m {
    padding-top: 20px;
  }
  .gaps-img {
    justify-content: space-around !important;
  }
  .gap-row {
    margin-top: 80px;
  }
  .side-slide {
    width: 100% !important;
  }
  .img-awards,
  .awards-div {
    width: 100% !important;
    border-radius: 5px;
  }
  .section-bg {
    height: auto !important;
  }
  .img-achive {
    width: 30px;
  }
  .row-achive {
    flex-direction: row !important;
  }
  .a-txt {
    font-size: 20px !important;
  }
  .a-txt-1 {
    font-size: 18px !important;
  }
  .animation-2-combine {
    width: 100% !important;
  }
  .reviews {
    width: 100% !important;
    padding: 20px !important;
  }
  .footer {
    height: auto !important;
    background-color: #000 !important;
  }
  .gaps-3 {
    padding-top: 50px !important;
  }

  .our-div {
    width: 100% !important;
    height: 250px !important;
  }
  .our-div > img {
    width: 100%;
    height: 250px !important;
  }
  .row-our {
    gap: 0px !important;
  }
  .gaps-row {
    gap: 30px !important;
  }
  .on-plan-row {
    row-gap: 20px;
  }
  .s-content {
    width: 100%;
    height: 500px !important;
  }
  .s-content-txt {
    width: 50% !important;
    justify-content: center;
  }
  .img-hand {
    width: 50% !important;
  }
  .img-hand > img {
    height: 300px !important;
    width: 100% !important;
  }
  .section-3 {
    padding-bottom: 40px;
  }
  .txt-ctn {
    padding-left: 20px !important;
  }
  .s-txt-s {
    font-size: 14px !important;
  }
  .bg-our {
    height: 500px !important;
  }
  .nav-icon {
    border: 1px solid #ffd169 !important;
    color: #ffd169 !important;
  }
  .txt-1-m {
    color: white;
    font-size: 23px !important;
  }
}
.txt-1-a {
  color: white !important;
  text-align: center;
  padding-top: 20px;
}
.txt-1-m {
  color: white;
  font-size: 20px;
}

.img-achive {
  width: 60px;
}

.btn-div-m {
  display: flex;
  justify-content: start;
  align-items: center;
}
.icons-m {
  display: flex;
  gap: 20px;
}
.product-div-1 {
  display: flex;
  width: 300px;
  align-items: center;
}
.m-txt {
  color: white;
}

.v-img-txt {
  font-size: 20px;
  font-weight: 600;
  color: white;
}
.v-combine-image {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
}
.continue{
  background-color: #000 !important;
  border: none !important;
  color: #ffd169 !important;
}

@media(max-width:768px){
  .css-3nwxr6{
    width: 350px !important;
  }
}

.card-div {
  margin: 0 auto;
  padding-top: 100px; 
}

.cont-div {
  padding-top: 150px;
}

.blog-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.blog-title {
  padding-top: 100px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  /* text-align: center; */
}

.blog-image {
  object-fit: cover;
  height: 70vh !important;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}

.blog-content h3 {
  margin-top: 20px;
  font-weight: bold;
}

.blog-content p {
  line-height: 1.8;
  font-size: 1.1rem;
}

.first-indent {
  text-indent: 30px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  justify-content: center;
  /* text-align: center; */
}

.col {
  display: flex;
  justify-content: center;
}
.bl{
list-style: none;
}